@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */

@font-face {
  font-family: "VistaSansReg";
  src: url("/fonts/vissr.ttf") format("truetype");
}

@font-face {
  font-family: "VistaSansBold";
  src: url("/fonts/vissb.ttf") format("truetype");
}

@font-face {
  font-family: "VistaSansLight";
  src: url("/fonts/VistaSansLight.ttf") format("truetype");
}

@font-face {
  font-family: "VistaSansAltReg";
  src: url("/fonts/vissar.ttf") format("truetype");
}

@font-face {
  font-family: "humanistBD";
  src: url("/fonts/HUM521BD.ttf") format("truetype");
}

@font-face {
  font-family: "humanistIT";
  src: url("/fonts/HUM521IT.ttf") format("truetype");
}

@font-face {
  font-family: "humanistRM";
  src: url("/fonts/HUM521RM.ttf") format("truetype");
}
@font-face {
  font-family: "humanistBDITBT";
  src: url("/fonts/HUMANIST521BOLDITALICBT.ttf") format("truetype");
}

@font-face {
  font-family: "blackadder";
  src: url("/fonts/ITCBLKAD.ttf") format("truetype");
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  overflow-y: scroll;
}

::-ms-reveal {
  display: none !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

/**
  This code is part of the create-react-app
**/
body {
  line-height: 1;
  margin: 0;
  font-family: "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiIconButton-root:hover,
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
  cursor: default;
}

a:hover,
.MuiSelect-select:hover,
.MuiButtonBase-root:hover {
  cursor: pointer;
}

.MuiTouchRipple-root {
  display: none;
}

a:-webkit-any-link {
   cursor: pointer;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    /* display: grid; */ /* 1st */
    /* height: 350px; */ /* 1st */
    position: fixed; /* 2nd */
    justify-content: center;
    align-items: center;
    top: 50%; /* 2nd */
    left: 48%; /* 2nd */
}

